export function index() {
	// header lang
	var $headerLang = $('.header__lang');
	$headerLang.find('.language-open').on('click', (e) => {
		var elem = $(e.currentTarget);

		elem.siblings('ul').toggleClass('show');
	});
	$headerLang.find('.language-close').on('click', (e) => {
		var elem = $(e.currentTarget);
		elem.closest('ul').toggleClass('show');
	});

	// header search
	$('.header__search').find('a').on('click', (e) => {
		e.preventDefault();
		var elem = $(e.currentTarget);

		elem.siblings('input').toggleClass('active').focus();
	});

	// carousel
	var $previewCarousel = $('.preview-list').owlCarousel({
		items: 1,
		dots: 1,
		nav: 0,
		navText: ["<span><i class='fas fa-angle-left'></i></span>", "<span><i class='fas fa-angle-right'></i></span>"],
		mouseDrag: false,
		touchDrag: false,
		onInitialized: function() {
			$('.preview-list').find('.owl-dots').addClass('container');
			$('.preview-list').find('.owl-dot').each((index, item) => $(item).find('span').text( index + 1 ) );
		}
	});

	var $articlesList = $('.articles-block__list.owl-carousel').owlCarousel({
		margin: $(window).innerWidth() > 1200 ? 25 : 5,
		items: 3,
		dots: 0,
		nav: 1,
		navText: ["<span><i class='fal fa-angle-left'></i></span>", "<span><i class='fal fa-angle-right'></i></span>"],
		responsive: {
			0: 		{ items: 1 },
			600: 	{ items: 2 },
			1000: 	{ items: 3 }
		}
	});

	var $personList = $('.person-list').owlCarousel({
		items: 1,
		dots: 0,
		nav: 1,
		navText: ["<span><i class='fal fa-angle-left'></i></span>", "<span><i class='fal fa-angle-right'></i></span>"],
	});

	var $partnersCarousel = $('.partners-list').owlCarousel({
		items: 4,
		dots: 0,
		nav: 1,
		navText: ["<span><i class='fal fa-angle-left'></i></span>", "<span><i class='fal fa-angle-right'></i></span>"],
		responsive: {
			0: 		{ items: 1 },
			600: 	{ items: 3 },
			1000: 	{ items: 4 }
		}
	});
}