import {index} 		from './components/index';
import {news} 		from './components/news';
import {busines} 	from './components/busines';
import {about} 		from './components/about';
import {consult} 	from './components/consult';

import {settings} 	from './components/personal/settings';

jQuery(window).load(() => {
	$('#preloader').fadeOut(500, function() {
		$(this).remove();
	});

	var width 	= $(window).innerWidth();
	var height 	= $(window).innerHeight();

	// sticky header
	$(window).load((e) => stickyHeader(e));
	$(window).scroll((e) => stickyHeader(e));

	// init modules
	index();
	news();
	busines();
	about();
	consult();
	settings();

	// lightgallery
	$('.lightgallery').lightGallery({
		selector: 'a',
		thumbnail: true,
		download: false,
	});

	// init input mask
	$('.input-mask-phone').inputmask("mask", {
		"mask": "+7 (999) 999-99-99"
	});

	// init select2
	$('select').select2({ minimumResultsForSearch: Infinity });

	// datepciker
	$('.datepicker').datepicker({
		language: 'ru',
		templates: {
		    leftArrow: '<i class="fal fa-angle-left"></i>',
		    rightArrow: '<i class="fal fa-angle-right"></i>'
		}
	});
});

function stickyHeader(element) {
	var element 	= $(element.currentTarget);
	var scrollTop 	= element.scrollTop();
	var $header 	= $('header.header');

	if ( scrollTop > $header.innerHeight() ) {
		$header.addClass('header_sticky');
	} else {
		$header.removeClass('header_sticky');
	}
}