export function consult() {
	var $consultItem = $('.consult-list__item');
	if ( $consultItem.length ) {
		$consultItem.find('a').on('click', (e) => {
			e.preventDefault();
			var elem = $(e.currentTarget);

			elem.siblings('.content-box').slideToggle(500).toggleClass('active');
		});
	}
}