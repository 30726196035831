export function news() {
	var $imageBoxList = $('.p-news-single-content .image-box-list.owl-carousel').owlCarousel({
		margin: $(window).innerWidth() > 1200 ? 25 : 5,
		items: 3,
		dots: 0,
		nav: 1,
		navText: ["<span><i class='fal fa-angle-left'></i></span>", "<span><i class='fal fa-angle-right'></i></span>"],
		mouseDrag: false,
		touchDrag: false,
		responsive: {
			0: 		{ items: 1 },
			600: 	{ items: 2 },
			1000: 	{ items: 3 }
		}
	});
}