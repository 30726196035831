export function busines() {
	var $businesList = $('.busines-content__block-list.owl-carousel').owlCarousel({
		margin: $(window).innerWidth() > 1200 ? 25 : 5,
		items: 3,
		dots: 0,
		nav: 1,
		navText: ["<span><i class='fal fa-angle-left'></i></span>", "<span><i class='fal fa-angle-right'></i></span>"],
		responsive: {
			0: 		{ items: 1 },
			600: 	{ items: 2 },
			1000: 	{ items: 2 },
			1200: 	{ items: 3 }
		}
	});

	var $businesList = $('.busines-single-content__block-more__list.owl-carousel').owlCarousel({
		margin: $(window).innerWidth() > 1200 ? 25 : 5,
		items: 4,
		dots: 0,
		nav: 1,
		navText: ["<span><i class='fal fa-angle-left'></i></span>", "<span><i class='fal fa-angle-right'></i></span>"],
		responsive: {
			0: 		{ items: 1 },
			600: 	{ items: 2 },
			1000: 	{ items: 3 },
			1200: 	{ items: 4 }
		}
	});
}