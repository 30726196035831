export function settings() {
	$('.form-group-toggle').find('a, button').on('click', (e) => {
		e.preventDefault();
		var elem 	= $(e.currentTarget);
		var form 	= elem.closest('form');
		var parent 	= elem.parent();

		parent.toggleClass('form-group_toggled');
		form.find('.form-group-changed').toggleClass('form-group_hidden');
		form.find('.form-group-changed').find('input[name]').val(null);
	});
}