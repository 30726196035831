export function about() {
	if ( $('#map').length ) {
		var map;
		var coord = [46.834777, 29.632151];

		ymaps.ready(function () {
			var map = new ymaps.Map('map', {
				center: coord,
				zoom: 10,
        		controls: []
			});

			var placemark = new ymaps.Placemark(coord);
			map.geoObjects.add(placemark);	
		});
	}
}